import React from "react";
import { Modal, Form, Input, Button, FormInstance } from "antd";
import { ILocation } from "../../../../components/UserAdministration/libs/Location.type";
import { IStoreFormData } from "../types";

interface StoreModalProps {
   visible: boolean;
   onCancel: () => void;
   onSubmit: (values: IStoreFormData) => Promise<void>;
   editingStore: ILocation | null;
   form: FormInstance;
   regions?: boolean;
}

export const StoreModal: React.FC<StoreModalProps> = ({ visible, onCancel, onSubmit, editingStore, form, regions }) => {
   return (
      <Modal title={editingStore ? "Edit Store" : "Add New Store"} open={visible} onCancel={onCancel} footer={null} width={500}>
         <Form form={form} layout="vertical" onFinish={onSubmit}>
            {!editingStore && (
               <Form.Item name="locationRef" label="Store Number" rules={[{ required: true }]}>
                  <Input />
               </Form.Item>
            )}
            <Form.Item name="name" label="Store Name" rules={[{ required: true }]}>
               <Input />
            </Form.Item>
            {!regions && (
               <Form.Item name="regionRef" label="Region">
                  <Input />
               </Form.Item>
            )}
            <Form.Item name="email" label="Email">
               <Input type="email" />
            </Form.Item>
            <Form.Item name="phone" label="Phone">
               <Input type="tel" />
            </Form.Item>
            <Form.Item className="mb-0 flex justify-end">
               <Button onClick={onCancel} className="mr-2">
                  Cancel
               </Button>
               <Button type="primary" htmlType="submit">
                  {editingStore ? "Update Store" : "Add Store"}
               </Button>
            </Form.Item>
         </Form>
      </Modal>
   );
};
