import axios from "axios";
import { authLocalStorage } from "../../../authServices/jwt";
import { LoginResponse } from "./libs/types/LoginResponse";

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

const tokenURL = "/newAuth";

class Keycloak {
  async getTenantToken(username: string, password: string) {
    try {
      const response = await authInstance.post<LoginResponse>(
        tokenURL,
        {
          username,
          password,
          role: "SC",
        }
      );

      return response;
    } catch (error) {
      console.error("Error getting tenant token:", error);
      throw error;
    }
  }

  async refreshToken() {
    try {
      const refreshToken = authLocalStorage.getRefreshToken();

      if (!refreshToken) return null;

      const response = await authInstance.post<LoginResponse>(
        tokenURL,
        {
          refreshToken,
          role: "SC",
        }
      );

      return response;
    } catch (error) {
      return null;
    }
  }
}

export const keycloackService = new Keycloak();
