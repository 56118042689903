import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { authLocalStorage, sessionShopHash, sessionToken } from "../../../../authServices/jwt";
import Loading from "../../../../components/UI/Loading";
import { categories as defaultCategories } from "../../../../configs/data/categories";
import { GetAuthStateContext } from "../../../../utility/context/authContext";
import { capitalizing, toggleSidebarMobile } from "../../../../utility/helpers";
import { SubCategory, getFilterSubmenuByRole } from "../../../../utility/role/getFilterSubmenuByRole";
import { getUserVisitPermission } from "../../../../utility/role/getUserVisistPermission";
import { getLogoutSuccess } from "../../../../utility/store/action/authAction/authAction";
import { SidebarContainer } from "./sidebar.styles";
import { useIntegrationContext } from "../../../../utility/store/context/IntegrationContext/useIntegrationContext";
import { ConfigurationIcon } from "../../../../assets/icon/ConfigurationIcon";
import { checkModuleAccess, Modules } from "../../../../utility/helpers/checkModuleAccess";

interface IProps {
   showModalLogin: () => void;
}

function Sidebar({ showModalLogin }: IProps) {
   const [collapsed, setCollapsed] = useState(false);
   const [category, setCategory] = useState(defaultCategories);
   const history = useHistory();
   const { dispatch, authState } = GetAuthStateContext();
   const { loading, activatedIntegrations } = useIntegrationContext();
   // const [isUserHaveAccess, setIsUserHaveAccess] = useState(false);

   const pathname = history.location.pathname.split("/").filter((item) => item !== "");
   const [openKey, setOpenKey] = useState([`${pathname[0]}`]);
   const [selectKey, setSelectKey] = useState([
      pathname[0] === "app-configurations" || pathname[0] === "reporting" || pathname[0] === "notifications"
         ? pathname[0] + "/" + pathname[1]
         : pathname[0],
   ]);

   const rootKey = category.map((cate) => cate.key);

   useEffect(() => {
      if (loading) return;

      const newCategories = [...defaultCategories];

      if (!activatedIntegrations?.some((item) => item.active && item.notificationService !== "SEND_GRID")) {
         newCategories.splice(3, 0, {
            id: "9",
            key: "notifications",
            icon: <ConfigurationIcon />,
            items: [{ key: "wishlist" }, { key: "quotes" }],
            label: "app configuration",
         });
      }

      if (checkModuleAccess(Modules.QUOTES)) {
         const reportingCategory = newCategories.find((cat) => cat.key === "reporting");

         if (reportingCategory) {
            const quoteItems = [
               {
                  key: "quotes",
                  tooltip: "View and download all quotes created in a given period",
               },
               {
                  key: "quote-conversion",
                  tooltip: "View and download all converted quotes in a given period including sales person and store that raised the quote",
               },
            ];

            quoteItems.forEach((item) => {
               const exists = reportingCategory.items?.some((i) => i.key === item.key);
               if (!exists) {
                  reportingCategory.items?.unshift(item);
               }
            });
         }
      }

      if (checkModuleAccess(Modules.STORE_POS)) {
         const reportingCategory = newCategories.find((cat) => cat.key === "reporting");

         if (reportingCategory) {
            const customerMessageItem = {
               key: "customer-message",
               tooltip: "View and download all messages sent and received by location and staff member for a given period",
            };
   
            const items = reportingCategory.items || [];
            const customerSubscriptionsIndex = items.findIndex(item => item.key === "customer-subscriptions");
   
            if (!items.some((item) => item.key === customerMessageItem.key)) {
               if (customerSubscriptionsIndex !== -1) {
                  items.splice(customerSubscriptionsIndex + 1, 0, customerMessageItem);
               } else {
                  items.push(customerMessageItem);
               }
            }
         }
      }

      setCategory(newCategories);

      return () => {
         setCategory(defaultCategories);
      };
   }, [loading, activatedIntegrations]);

   useEffect(() => {
      const updatedCategories = [...defaultCategories];
      const userAdminCategory = updatedCategories.find((cat) => cat.key === "user-administration");

      const timeoutId = setTimeout(() => {
         if (userAdminCategory && checkModuleAccess(Modules.STORE_POS)) {
            const isStaffItemExists = userAdminCategory.items?.some((item) => item.key === "staff");

            if (!isStaffItemExists) {
               userAdminCategory.items?.push({
                  label: "Add Store Staff",
                  key: "staff",
                  tooltip: "",
               });
            }
         } else if (userAdminCategory) {
            userAdminCategory.items = userAdminCategory.items?.filter((item) => item.key !== "staff");
         }

         setCategory(updatedCategories);
      }, 1000);

      return () => {
         clearTimeout(timeoutId);
      };
   }, []);

   const setDefaultSelectedKey = () => {
      const pathname = history.location.pathname.split("/").filter((item) => item !== "");
      setSelectKey([pathname[1] ? pathname[0] + "/" + pathname[1] : pathname[0]]);
   };

   const onClickDashboard = (e: any) => {
      if (e.key === "documentation") {
         window.open("https://thewishlistco.stoplight.io/", "_blank");
         return;
      }
      if (e.key === "logout") {
         authLocalStorage.clearTokens();
         window.localStorage.clear();
         sessionShopHash.clearShopHash();
         authLocalStorage.clearSessionAppInside();
         dispatch(getLogoutSuccess());
         window.location.reload();
         history.push("/auth");
         return;
      }
      history.push("/" + e.key);
      toggleSidebarMobile();
      setDefaultSelectedKey();
   };

   const onOpenChange = (keys: any[]) => {
      const latestOpenKey = keys.find((key) => openKey.indexOf(key) === -1);
      // const _sessionHash = sessionToken.getToken();
      // if (latestOpenKey === "app-configurations" && !_sessionHash) {
      //    showModalLogin();
      //    return;
      // }
      if (latestOpenKey && rootKey.indexOf(latestOpenKey) === -1) {
         setOpenKey(keys);
      } else {
         setOpenKey(latestOpenKey ? [latestOpenKey] : []);
      }
   };

   const renderSidebar = () => {
      const filterPermissionCategories = category.filter((item) => {
         if (!authState.auth || !authState.auth.status || !item.key) return false;

         item.items = getFilterSubmenuByRole(authState.auth.status, item.items as SubCategory[]) as any;

         return getUserVisitPermission(authState.auth.status, item.key);
      });

      return filterPermissionCategories.map((cate) => {
         const { key, icon, items } = cate;

         if (!items || items.length === 0) {
            return (
               <Menu.Item key={`${key}`} icon={icon}>
                  {capitalizing(key || "")}
               </Menu.Item>
            );
         }
         const menuItem = items.map((item: any) => (
            <Menu.Item title={item?.tooltip} key={`${key}/${item.key}`}>
               {item?.label || capitalizing(item.key)}
            </Menu.Item>
         ));

         return (
            <Menu.SubMenu key={key} title={capitalizing(key || "")} icon={icon}>
               {menuItem}
            </Menu.SubMenu>
         );
      });
   };

   return (
      <>
         <SidebarContainer
            className="sidebar menu-custom"
            collapsible
            collapsed={collapsed}
            onCollapse={() => {
               setCollapsed((prevState) => !prevState);
            }}
            theme="light"
            width="240px"
         >
            <Menu
               theme="light"
               defaultSelectedKeys={[pathname[1] ? pathname[0] + "/" + pathname[1] : pathname[0]]}
               selectedKeys={selectKey}
               openKeys={openKey}
               onOpenChange={onOpenChange}
               mode="inline"
               onClick={onClickDashboard}
               style={{ position: "sticky", top: "10px" }}
            >
               {renderSidebar()}
            </Menu>
         </SidebarContainer>
         {authState.pending && <Loading />}
      </>
   );
}

export default Sidebar;
