import { authLocalStorage, fromShop } from "./../authServices/jwt/index";
import Axios, { AxiosRequestConfig } from "axios";
import { sessionShopHash, sessionToken } from "../authServices/jwt";
import { message } from "antd";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const axiosInstance = Axios.create({
  baseURL: baseUrl,
});

export const axiosInstanceLogin = Axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(function (config: AxiosRequestConfig) {
  // const _sessionToken = sessionToken.getToken();
  const accessToken = authLocalStorage.getToken();
  if (accessToken) {
    config.headers.Authorization = "Bearer " + accessToken;
  }
  return config;
});
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: any) {
    const errorStatuses = [401, 403, 400, 500];
    // if (errorStatuses.includes(error.response.status)) {
    //   if (window.location.pathname !== "/auth/sign-in") {
    //     window.location.href = '/auth/sign-in'; 
    //   }
    //   window.localStorage.clear();
    // } else if (error.response.status === 503) {
    //   message.error("You have been logged out. Please log in again");
    //   if (window.location.pathname !== "/auth/sign-in") {
    //     window.location.href = '/auth/sign-in'; 
    //   }
    // }
    return Promise.reject(error);
  }
);
axiosInstanceLogin.interceptors.request.use(function (
  config: AxiosRequestConfig
) {
  return config;
});
axiosInstanceLogin.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: any) {
    if (error.response.status === 503) {
      message.error("You have been logged out. Please log in again");
      if (window.location.pathname !== "/auth/sign-in") {
        window.location.href = '/auth/sign-in'; 
      }
    }
    return Promise.reject(error);
  }
);