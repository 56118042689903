import React from "react";
import logo from "../../../../assets/img/twc_logo.png";

import { Button, Select } from "antd";
import { LinkIcon } from "../../../../assets/icon/LinkIcon";
import { SettingIcon } from "../../../../assets/icon/SettingIcon";
import { sessionShopHash } from "../../../../authServices/jwt";
import { GetAuthStateContext } from "../../../../utility/context/authContext";
import { GetShopStateContext } from "../../../../utility/context/shopContext";
import { toggleMenuMobile, toggleSidebarMobile } from "../../../../utility/helpers";
import { HeaderContainer } from "./header.styles";
import { IShop } from "../../../../utility/interface/model/shop";
import { Roles } from "../../../../configs/rolePemisions/roles.enum";
import { LocalStorageKeys } from "../../../../authServices/jwt/libs/LocalStorage.enum";

function Header() {
   const { shopSingleState } = GetShopStateContext();
   const { authState } = GetAuthStateContext();

   const config = JSON.parse(localStorage.getItem(LocalStorageKeys.TWC_CONFIG) || "");
   const isMasterTenant = !!config?.isMasterTenant;
   const isMemberTenant = !!config?.isMemberTenant;
   
   const goToShop = () => {
      window.open(`https://${shopSingleState.detail?.domain}`, "_blank");
   };
   const changeShop = (shop_hash: any) => {
      sessionShopHash.setShopHash(shop_hash);
      window.location.reload();
   };
   const openSidebarHamburger = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      toggleSidebarMobile();
   };

   const openMenuHamburger = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      toggleMenuMobile();
   };

   const renderKeycloackShops = () => {
      const tenant = authState.auth?.tenant || sessionShopHash.getShopHash();
      const shops = [tenant];

      return shops.map((item) => (
         <Select.Option key={item} value={item}>
            {item}
         </Select.Option>
      ));
   };

   const renderRdsAuthShops = () => {
      return authState.auth?.shops?.map((item: IShop) => (
         <Select.Option key={item.id} value={item.shop_hash}>
            {item.shop_hash}
         </Select.Option>
      ));
   };

   return (
      <HeaderContainer>
         <div className="logo">
            <div className="nav-container" tabIndex={0} onClick={(e) => openSidebarHamburger(e)}>
               <div className="nav-toggle"></div>
            </div>
            <img src={logo} alt="logo" />
         </div>
         <div>
            <div className="shop-info">
               <span className="display-dk text-white text-[12px] mr-5">
                  {authState.auth?.tenant}
                  {isMemberTenant && <span> (Member)</span>}
                  {isMasterTenant && <span> (Master)</span>}
               </span>
               <Button className="button__shop-domain display-dk" ghost>
                  {authState.auth?.email}
               </Button>
               <div className="nav-menu--container" tabIndex={0} onClick={(e) => openMenuHamburger(e)}>
                  <div className="nav-toggle"></div>
               </div>
            </div>
         </div>
         {/* <div className="shop-info">
        {authState.auth && (
          <Select
            onChange={changeShop}
            defaultValue={_sessionShopHash}
            style={{
              minWidth: 120,
              color: "white",
              fontSize: "10px",
            }}
            bordered={false}
            className="display-dk"
          >
            {authState.auth.status.includes(Roles.OldAuthMethod) 
              ? renderRdsAuthShops()
              : renderKeycloackShops()}
          </Select>
        )}
        <SettingIcon className="display-dk" />
      </div> */}
      </HeaderContainer>
   );
}

export default Header;
