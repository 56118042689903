import { Card } from "antd";
import LineChartWrapper from "./charts/LineChartWrapper";
import StackedBarChartWrapper from "./charts/StackedBarChartWrapper";
import BarChartWrapper from "./charts/BarChartWrapper";
import useStoreFilter from "./hooks/useStoreFilter";
import AreaChartWrapper from "./charts/AreaChartWrapper";
import { ChartType } from "../Dashboard/SimpleBoxMain/clickHouseApi/types";

const StorePerformance = () => {
   const { filter, dateRange, selectedLocation } = useStoreFilter();

   const chartData = [
      {
         title: "Interactions per day by type",
         chart: <LineChartWrapper interactionType={ChartType.INTERACTION_BY_TYPE_PERDAY} dateRange={dateRange} selectedLocation={selectedLocation} />,
      },
      // { title: "Interactions by type", chart: <PieChartWrapper dateRange={dateRange} selectedLocation={selectedLocation} /> },
      {
         title: "Interactions by store",
         chart: <StackedBarChartWrapper multiple interactionType={ChartType.INTERACTION_BY_STORE} dateRange={dateRange} selectedLocation={selectedLocation} />,
      },
      {
         title: "",
         chart: (
            <div className="grid xl:grid-cols-3">
               <BarChartWrapper
                  title="Messages interactions"
                  interactionType={ChartType.MESSAGE_INTERACTION}
                  dateRange={dateRange}
                  selectedLocation={selectedLocation}
               />
               <BarChartWrapper
                  title="Contact interactions"
                  interactionType={ChartType.CUSTOMER_INTERACTION}
                  dateRange={dateRange}
                  selectedLocation={selectedLocation}
               />
               <BarChartWrapper
                  title="Wishlist & Wishlist Item interactions"
                  interactionType={ChartType.WISHLIST_INTERACTION}
                  dateRange={dateRange}
                  selectedLocation={selectedLocation}
               />
            </div>
         ),
      },
      {
         title: "Conversions per day",
         chart: <AreaChartWrapper tooltip="The number of orders for each day in the specified period that included an item that was on that customer’s wishlist, or that they had received in a message from a team member" interactionType={ChartType.CONVERSION_COUNT_PERDAY_STORE} dateRange={dateRange} selectedLocation={selectedLocation} />,
      },
      {
         title: "Conversion value per day",
         chart: <AreaChartWrapper tooltip="The total value of orders for each day in the specified period where that order included an item that was either on that customer’s wishlist, or that they had received in a message from a team member" interactionType={ChartType.CONVERSION_VALUE_PERDAY_STORE} dateRange={dateRange} selectedLocation={selectedLocation} />,
      },
      {
         title: "",
         chart: (
            <div className="grid xl:grid-cols-2">
               <div>
                  <h3 className="font-semibold text-[18px]">Conversions by store (number)</h3>
                  <StackedBarChartWrapper interactionType={ChartType.CONVERSION_STORE_NUMBER} dateRange={dateRange} selectedLocation={selectedLocation} />
               </div>
               <div>
                  <h3 className="font-semibold text-[18px]">Conversions by store (value $ )</h3>
                  <StackedBarChartWrapper interactionType={ChartType.CONVERSION_STORE_VALUE} dateRange={dateRange} selectedLocation={selectedLocation} />
               </div>
            </div>
         ),
      },
      // {
      //    title: "Conversions rate % of total member sales",
      //    chart: <RateChartWrapper dateRange={dateRange} selectedLocation={selectedLocation} />,
      // },
     
   ];

   return (
      <div className="w-full bg-white">
         <div className="px-4 w-full">
            <Card title="Store Performance" extra={filter}>
               {chartData.map((item, index) => (
                  <Card key={index}>
                     {item.title && <h3 className="font-semibold text-[18px]">{item.title}</h3>}
                     {item.chart}
                  </Card>
               ))}
            </Card>
         </div>
      </div>
   );
};

export default StorePerformance;
