import { LocalStorageKeys } from "../../authServices/jwt/libs/LocalStorage.enum";

export enum Modules {
   STORE_POS = "storePos",
   QUOTES = "quotes",
   EMARSYS = "emarsys",
}

export const checkModuleAccess = (moduleName: Modules) => {
   const twcModulesString = localStorage.getItem(LocalStorageKeys.TWC_MODULES);
   if (twcModulesString !== "undefined") {
      const twcModules = twcModulesString ? JSON.parse(twcModulesString) : [];
      return twcModules.includes(moduleName);
   }
   return false;
};