import { LogoutOutlined, ShopOutlined } from "@ant-design/icons";
import { ConfigurationIcon } from "../../assets/icon/ConfigurationIcon";
import { DashboardIcon } from "../../assets/icon/DashboardIcon";
import { DocumentationIcon } from "../../assets/icon/DocumentationIcon";
import { ReportingIcon } from "../../assets/icon/ReportIcon";
import { UserIcon } from "../../assets/icon/UserIcon";

interface category {
   id?: string;
   key?: string;
   icon?: React.ReactNode;
   label?: string;
   items?: subCategory[];
}

interface subCategory {
   key?: string;
   tooltip?: string;
   label?: string;
}

export const categories: category[] = [
   {
      id: "1",
      key: "wishlist-dashboard",
      icon: <DashboardIcon />,
      label: "wishlist-dashboard",
      items: [],
   },
   {
      id: "2",
      key: "store-&-staff-dashboard",
      icon: <DashboardIcon />,
      label: "Store & Staff dashboard",
      items: [],
   },
   {
      id: "3",
      key: "quote-dashboard",
      icon: <DashboardIcon />,
      label: "quote-dashboard",
      items: [],
   },
   // {
   //   id: "2",
   //   key: "subscription",
   //   icon: <SubscriptionIcon />,
   //   label: "subscription",
   //   items: [],
   // },
   {
      id: "4",
      key: "integrations",
      icon: <ConfigurationIcon />,
      label: "Integrations",
      items: [],
   },
   {
      id: "5",
      key: "app-configurations",
      icon: <ConfigurationIcon />,
      items: [{ key: "launch-point" }, { key: "add-items-to-wishlist" }, { key: "wishlist-page" }],
      label: "app configuration",
   },
   // {
   //   id: "9",
   //   key: "notifications",
   //   icon: <ConfigurationIcon />,
   //   items: [{ key: "wishlist" }, { key: "quotes" }],
   //   label: "app configuration",
   // },
   {
      id: "6",
      key: "user-administration",
      icon: <UserIcon />,
      items: [
         {
            label: "Manage Users",
            key: "users",
            tooltip: "",
         },
         {
            label: "Manage Store Staff",
            key: "staff",
            tooltip: "",
         }
      ],
      label: "user administration",
   },
   {
      id: "7",
      key: "manage-stores-&-regions",
      icon: <ShopOutlined style={{ fontSize: "20px" }} />,
      label: "Store & Region Management",
      items: [],
   },
   {
      id: "8",
      key: "reporting",
      icon: <ReportingIcon />,
      label: "reporting",
      items: [
         {
            key: "quotes",
            tooltip: "View and download all quotes created in a given period",
         },
         {
            key: "quote-conversion",
            tooltip: "View and download all converted quotes in a given period including sales person and store that raised the quote",
         },
         {
            key: "customer-query",
            tooltip: "Search for and download the wishlist details for a specific customer",
         },
         {
            key: "customer-subscriptions",
            tooltip: "View and download (or push to your marketing platform) the list of customers that have registered their interest in a product, or subscribed to be notified when an item is back in stock",
         },
         {
            key: "customer-message",
            tooltip: "View and download all messages sent and received by location and staff member for a given period",
         },
         {
            label: "Product Query",
            key: "product-report",
            tooltip: "View the popularity of a specific product, and download a list of customers that have that product on a wishlist",
         },
         {
            key: "top-brands",
            tooltip:
               "View and download the most popular brands across your wishlists for a given period",
         },
         {
            key: "top-products",
            tooltip:
               "View and download the most popular products across your wishlists for a given period",
         },
         {
            key: "product-conversion",
            tooltip: "View and download a list of the highest converting products in a given period",
         },
         {
            key: "product-conversion-detail",
            tooltip: "View and download every wishlist conversion including product, customer, store and staff (where available) during a given period",
         },
         // {
         //   key: "all-customers",
         //   tooltip:
         //     "A summary of all customers that created wishlists in a given date range",
         // },
         {
            key: "customer-conversions",
            tooltip: "View and download a list of your top customers based on the number of items or total value they purchased from wishlists, during a given period ",
         },
         {
            key: "wishlist",
            tooltip: "View and download a list of all wishlists created during a given period, including the customer name and the items currently on the wishlist",
         },

         {
            key: "interactions",
            tooltip:
               "View and download a list of all interactions during a given period, including the type of interaction, customer, product, store and staff (where available",
         },
      ],
   },
   // {
   //   id: "3",
   //   key: "pos-config",
   //   icon: <ConfigurationIcon />,
   //   items: [
   //     { key: "staff" },
   //     { key: "outlet-store" },
   //     { key: "outlet-config" },
   //   ],
   //   label: "Outlet",
   // },
   
   {
      id: "9",
      key: "documentation",
      icon: <DocumentationIcon />,
      label: "documentation",
      items: [],
   },
   // {
   //   id: "7",
   //   key: "diagnostics",
   //   icon: <IntegrationIcon />,
   //   label: "Diagnostics",
   //   items: [],
   // },
   {
      id: "10",
      key: "logout",
      icon: <LogoutOutlined style={{ fontSize: "20px" }} />,
      // items: [{ key: "sign-out" }],
      items: [],
   },
];
