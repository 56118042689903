import { useState, useCallback } from 'react';
import { message } from 'antd';
import { regionService } from '../../../../api/services/region/region.service';
import { IRegion } from '../../../../api/services/region/region.service';
import { CreateRegionData, EditRegionData } from '../../../../api/services/region/region.service';

export const useRegions = () => {
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchRegions = useCallback(async () => {
        try {
            setLoading(true);
            const response = await regionService.getAllRegions();
            setRegions(response.data.regions || []);
        } catch (error) {
            message.error("Failed to fetch regions");
        } finally {
            setLoading(false);
        }
    }, []);

    const createRegion = async (values: CreateRegionData) => {
        try {
            await regionService.createRegion(values);
            message.success("Region added successfully");
            await fetchRegions();
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to add region";
            message.error(errorMessage);
            throw error;
        }
    };

    const updateRegion = async (id: string, values: Partial<EditRegionData>) => {
        try {
            const existingRegion = regions.find(region => region.id === id);
            if (!existingRegion) {
                throw new Error('Region not found');
            }

            const payload: EditRegionData = {
                id,
                regionName: values.regionName || existingRegion.regionName,
                regionRef: values.regionRef || existingRegion.regionRef
            };

            await regionService.editRegion(payload);
            message.success("Region updated successfully");
            await fetchRegions();
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to update region";
            message.error(errorMessage);
            throw error;
        }
    };

    const deleteRegion = async (regionId: string): Promise<void> => {
        try {
            await regionService.deleteRegion(regionId);
            message.success("Region deleted successfully");
            await fetchRegions();
        } catch (error) {
            message.error("Failed to delete region");
        }
    };

    return {
        regions,
        loading,
        fetchRegions,
        createRegion,
        updateRegion,
        deleteRegion,
    };
}; 