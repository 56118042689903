import { useState, useEffect, useMemo } from "react";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { ChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";

interface ChartData {
   D: string;
   C: string;
   value: string;
   source: string;
}

interface UseAreaChartProps {
   dateRange: DateRange;
   selectedLocation: string[];
   interactionType: ChartType;
}

export const useAreaChart = ({ dateRange, selectedLocation, interactionType }: UseAreaChartProps) => {
   const [rawData, setRawData] = useState<ChartData[]>([]);

   useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
         try {
            const response = await ClickHouseDashboardApi.getLocationGraphData(dateRange, interactionType, selectedLocation);
            const data = JSON.parse(response).dashboardChart;

            if (isMounted) {
               setRawData(data);
            }
         } catch (error) {
            console.error("Failed to fetch data:", error);
         }
      };

      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, selectedLocation, interactionType]);

   const transformData = useMemo(() => {
      if (!rawData || rawData.length === 0) return [];

      const sources = Array.from(new Set(rawData.filter((item) => item.source).map((item) => item.source)));

      const getInitialSourceValues = () => {
         const initial: Record<string, number | string> = { date: "" };
         sources.forEach((source) => {
            initial[source] = 0;
         });
         return initial;
      };

      const groupedData = rawData.reduce((acc: any, item) => {
         if (!item.D) return acc;

         const value = Number(interactionType === "CONVERSION_COUNT_PERDAY_STORE" ? item.C : item.value);

         if (value === 0) return acc;

         const date = item.D;
         if (!acc[date]) {
            acc[date] = {
               ...getInitialSourceValues(),
               date,
            };
         }

         if (item.source) {
            acc[date][item.source] = value;
         }

         return acc;
      }, {});

      return Object.values(groupedData);
   }, [rawData, interactionType]);

   const sources = useMemo(() => Array.from(new Set(rawData.filter((item) => item.source).map((item) => item.source))), [rawData]);

   return {
      transformData,
      sources,
   };
};
