import { useState, useCallback } from 'react';
import { message } from 'antd';
import { locationService } from '../../../../api/services/location/location.service';
import { ILocation } from '../../../../components/UserAdministration/libs/Location.type';
import { LocationCreatePayload, LocationUpdatePayload } from '../../../../api/services/location/location.service';

export const useStores = () => {
    const [locations, setLocations] = useState<ILocation[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchLocations = useCallback(async () => {
        try {
            setLoading(true);
            const response = await locationService.getAllLocations();
            setLocations(response.data.locations || []);
        } catch (error) {
            message.error("Failed to fetch stores");
        } finally {
            setLoading(false);
        }
    }, []);

    const createStore = async (values: LocationCreatePayload) => {
        try {
            if (!values.locationRef) {
                throw new Error('Location reference is required');
            }

            await locationService.createLocation(values);
            message.success("Store created successfully");
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to create store";
            message.error(errorMessage);
            throw error;
        }
    };

    const updateStore = async (id: string, values: Partial<LocationUpdatePayload>) => {
        try {
            if (!values.locationRef) {
                throw new Error('Location reference is required');
            }

            const payload: LocationUpdatePayload = {
                ...values,
                id,
                locationRef: values.locationRef,
                regionRef: values.regionRef || ''
            };

            await locationService.editLocation(payload);
            message.success("Store updated successfully");
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to update store";
            message.error(errorMessage);
            throw error;
        }
    };

    const deleteStore = async (locationId: string, locationRef: string) => {
        try {
            await locationService.deleteLocation(locationId, locationRef);
            message.success("Store deleted successfully");
            await fetchLocations();
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to delete store";
            message.error(errorMessage);
            return false;
        }
    };

    return {
        locations,
        loading,
        fetchLocations,
        createStore,
        updateStore,
        deleteStore,
    };
}; 