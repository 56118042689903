import { sessionShopHash } from "../../../authServices/jwt";
import { axiosDashboard } from "../../../views/pages/Dashboard/config/AxiosSetting";

export interface IRegion {
   id: string;
   regionRef: string;
   regionName: string;
   createdTime: string;
   modifiedTime: string;
   deleted: boolean;
}

export interface CreateRegionData {
   regionRef: string;
   regionName: string;
}

export interface EditRegionData {
   id: string;
   regionRef: string;
   regionName: string;
}

class RegionService {
   getAllRegions = (pageSize: number = 50, lastItemId?: string) => {
      return axiosDashboard.get(
         `services/inventorysvc/api/allRegion`,
         {
            params: {
               tenantId: sessionShopHash.getTenant(),
               pageSize,
               lastItemId,
            },
         }
      );
   };

   getAllLocationForRegion = (regionRef: string) => {
      return axiosDashboard.get(
         `services/inventorysvc/api/region/${regionRef}/getLocations`,
         {
            params: {
               tenantId: sessionShopHash.getTenant(),
            },
         }
      );
   };

   createRegion = (region: CreateRegionData) => {
      return axiosDashboard.post("services/inventorysvc/api/region", region);
   };

   editRegion = (region: EditRegionData) => {
      return axiosDashboard.put(`services/inventorysvc/api/region/id=${region.id}`, {
         regionName: region.regionName,
      });
   }

   deleteRegion = (regionId: string) => {
      return axiosDashboard.delete(`services/inventorysvc/api/region/${regionId}`);
   }
}

export const regionService = new RegionService();
