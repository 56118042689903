import React, { useState, useEffect } from "react";
import { Button, Table, Input, Space, Popconfirm, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useStores } from "../hooks/useStores";
import { StoreModal } from "./LocationModal";
import { Form } from "antd";
import { ILocation } from "../../../../components/UserAdministration/libs/Location.type";

const { Search } = Input;

export const LocationsTab: React.FC<{ shouldRefresh?: boolean }> = ({ shouldRefresh }) => {
   const { locations, loading, fetchLocations, createStore, updateStore, deleteStore } = useStores();

   const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
   const [selectedStore, setSelectedStore] = useState<ILocation | null>(null);
   const [storeSearchQuery, setStoreSearchQuery] = useState("");
   const [storeForm] = Form.useForm();

   useEffect(() => {
      fetchLocations();
   }, [fetchLocations, shouldRefresh]);

   const handleStoreModalClose = () => {
      setIsStoreModalOpen(false);
      setSelectedStore(null);
      storeForm.resetFields();
   };

   const handleStoreEdit = (store: ILocation) => {
      setSelectedStore(store);
      storeForm.setFieldsValue({
         name: store.name,
         email: store.email,
         phone: store.address?.phone,
         locationRef: store.locationRef,
         regionRef: store.regionRef,
      });
      setIsStoreModalOpen(true);
   };

   const handleStoreSubmit = async (values: any) => {
      try {
         if (selectedStore) {
            const updatedStore = {
               id: selectedStore.id,
               locationRef: selectedStore.locationRef,
               regionRef: values.regionRef || selectedStore.regionRef,
               name: values.name,
               email: values.email,
               address: {
                  ...selectedStore.address,
                  phone: values.phone,
               },
            };

            await updateStore(selectedStore.id || "", updatedStore);
         } else {
            const newStore = {
               locationRef: values.locationRef,
               regionRef: values.regionRef || '',
               name: values.name,
               email: values.email,
               address: {
                  phone: values.phone,
               },
            };
            
            await createStore(newStore);
         }
         
         handleStoreModalClose();
         await fetchLocations();
      } catch (error) {
         console.error('Failed to save store:', error);
         setIsStoreModalOpen(true);
      }
   };

   const filteredStores = locations.filter(
      (store) =>
         store.name?.toLowerCase().includes(storeSearchQuery.toLowerCase()) || store.locationRef?.toLowerCase().includes(storeSearchQuery.toLowerCase())
   );

   const locationColumns = [
      {
         title: "Store Number",
         dataIndex: "locationRef",
         key: "locationRef",
         render: (text: string) => text || "-",
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         render: (text: string) => text || "-",
      },
      {
         title: "Region",
         dataIndex: "regionRef",
         key: "regionRef",
         render: (text: string) => text || "-",
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
         render: (text: string) => text || "-",
      },
      {
         title: "Phone",
         dataIndex: ["address", "phone"],
         key: "phone",
         render: (text: string) => text || "-",
      },
      {
         title: "Actions",
         key: "actions",
         render: (_: any, record: ILocation) => (
            <Space>
               <Tooltip title="Edit">
                  <Button icon={<EditOutlined />} onClick={() => handleStoreEdit(record)} />
               </Tooltip>
               <Popconfirm
                  title="Are you sure you want to delete this store?"
                  onConfirm={() => deleteStore(record.id || "", record.locationRef || "")}
                  okText="Yes"
                  cancelText="No"
               >
                  <Tooltip title="Delete">
                     <Button icon={<DeleteOutlined />} danger />
                  </Tooltip>
               </Popconfirm>
            </Space>
         ),
      },
   ];

   return (
      <>
         <div className="flex justify-between items-center mb-4">
            <Search
               enterButton={false}
               placeholder="Search stores..."
               className="max-w-md"
               allowClear
               onChange={(e) => setStoreSearchQuery(e.target.value)}
            />
            <Button type="primary" className="!bg-[#59c3b8] !border-[#59c3b8]" onClick={() => setIsStoreModalOpen(true)}>
               Add Store
            </Button>
         </div>
         <Table columns={locationColumns} dataSource={filteredStores} loading={loading} rowKey="id" scroll={{ x: 'max-content' }} />
         <StoreModal visible={isStoreModalOpen} onCancel={handleStoreModalClose} onSubmit={handleStoreSubmit} editingStore={selectedStore} form={storeForm} />
      </>
   );
};
