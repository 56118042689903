import React from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { formatXAxis } from "../helpers";
import { ChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";
import { Tooltip as AntTooltip } from "antd";
import { useAreaChart } from "../hooks/useAreaChart";

interface AreaChartWrapperProps {
   dateRange: DateRange;
   selectedLocation: string[];
   interactionType: ChartType;
   tooltip?: string;
}

const AreaChartWrapper: React.FC<AreaChartWrapperProps> = ({ dateRange, selectedLocation, interactionType, tooltip }) => {
   const { transformData, sources } = useAreaChart({
      dateRange,
      selectedLocation,
      interactionType,
   });

   return (
      <AntTooltip title={tooltip || ""} placement="left">
         <div style={{ overflowX: "auto" }}>
            <div style={{ width: "100%" }}>
               <ResponsiveContainer width="100%" height={400}>
                  <AreaChart
                     data={transformData}
                     margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                     }}
                  >
                     <CartesianGrid strokeDasharray="3 3" />
                     <XAxis dataKey="date" tickFormatter={formatXAxis} />
                     <YAxis />
                     <Tooltip />
                     {sources.map((source, index) => (
                        <Area
                           key={source}
                           type="monotone"
                           dataKey={source}
                           stroke={index === 0 ? "#8884d8" : "#ff7300"}
                           fill={index === 0 ? "#8884d8" : "#ff7300"}
                           name={source.charAt(0) + source.slice(1).toLowerCase()}
                        />
                     ))}
                  </AreaChart>
               </ResponsiveContainer>
            </div>
         </div>
      </AntTooltip>
   );
};

export default AreaChartWrapper;
