import React, { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { IRegion } from '../../../../api/services/region/region.service';
import { IRegionFormData } from '../types';

interface RegionModalProps {
    visible: boolean;
    onCancel: () => void;
    onSubmit: (values: IRegionFormData) => Promise<void>;
    editingRegion: IRegion | null;
}

export const RegionModal: React.FC<RegionModalProps> = ({
    visible,
    onCancel,
    onSubmit,
    editingRegion
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (editingRegion) {
            form.setFieldsValue({
                regionName: editingRegion.regionName,
                regionRef: editingRegion.regionRef
            });
        } else {
            form.resetFields();
        }
    }, [editingRegion, form]);

    return (
        <Modal
            title={editingRegion ? "Edit Region" : "Add New Region"}
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={500}
        >
            <Form 
                form={form} 
                layout="vertical" 
                onFinish={onSubmit}
            >
                {!editingRegion && (
                    <Form.Item 
                        name="regionRef" 
                        label="Region Number" 
                        rules={[{ required: true, message: "Please input region number!" }]}
                    >
                        <Input />
                    </Form.Item>
                )}
                <Form.Item 
                    name="regionName" 
                    label="Region Name" 
                    rules={[{ required: true, message: "Please input region name!" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item className="mb-0 flex justify-end">
                    <Button onClick={onCancel} className="mr-2">
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {editingRegion ? "Update Region" : "Add Region"}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}; 