import { sessionShopHash } from "../../../authServices/jwt";
import { ILocation } from "../../../components/UserAdministration/libs/Location.type";
import { axiosDashboard } from "../../../views/pages/Dashboard/config/AxiosSetting";

export interface LocationUpdatePayload extends Partial<ILocation> {
  id: string;
  locationRef: string;
}

export interface LocationCreatePayload extends Omit<ILocation, 'id'> {
  locationRef: string;
}

class LocationService {
  async getLocationsByRef(locationRef: string) {
    return await axiosDashboard.get("services/inventorysvc/api/location", {
      params: {
        locationRef,
      },
    });
  }

  getAllLocations = (pageSize: number = 50, lastItemId?: string) => {
    return axiosDashboard.get(
      `services/inventorysvc/api/allLocations`,
      {
        params: {
          tenantId: sessionShopHash.getTenant(),
          pageSize,
          lastItemId,
        },
      }
    );
  };

  createLocation = (location: LocationCreatePayload) => {
    return axiosDashboard.post("services/inventorysvc/api/location", location);
  };

  editLocation = (location: LocationUpdatePayload) => {
    return axiosDashboard.put("services/inventorysvc/api/location", location, {
      params: { id: location.id }
    });
  }

  deleteLocation = (locationId: string, locationRef: string) => {
    return axiosDashboard.delete(`services/inventorysvc/api/location`, {
      params: { locationId, locationRef }
    });
  }
}

export const locationService = new LocationService();
