import { useEffect, useState, useCallback } from "react";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import Card from "../../Dashboard/SimpleBoxMain/Card";

interface DashboardData {
   totalQuotes: number;
   quotesConverted: number;
   totalRevenu: string;
   quotedValue: string;
   quotedProducts: number;
   quotedCustomer: number;
   customersConverted: number;
   avgQuotePerCustomer: string;
   avgConvertionRate: string;
   avgConvertionPeriod: string;
}

const QuoteCards = ({ dateRange, selectedLocation }: { dateRange: DateRange; selectedLocation: string[] }) => {
   const [data, setData] = useState<DashboardData | null>(null);
   const [notificationsData, setNotificationsData] = useState<any | null>(null);
   const [loading, setLoading] = useState(false);

   const fetchData = useCallback(async () => {
      setLoading(true);
      try {
         const responseQuote = await ClickHouseDashboardApi.getQuoteStats(
            {
               from: dateRange.from,
               to: dateRange.to,
            },
            selectedLocation
         );
         setData(JSON.parse(responseQuote).dashboardData);
      } catch (error) {
         console.error("Error fetching quote stats:", error);
      }

      try {
         const responseNotifications = await ClickHouseDashboardApi.getKpiStats({ from: dateRange.from, to: dateRange.to }, "NOTIFICATION_DASHBOARD");
         setNotificationsData(JSON.parse(responseNotifications)?.dashboardChart);
      } catch (error) {
         console.error("Error fetching notifications data:", error);
      } finally {
         setLoading(false);
      }
   }, [dateRange, selectedLocation]);

   useEffect(() => {
      fetchData();
   }, [fetchData, dateRange]);

   const formatNumber = (num: number | string | undefined) => {
      if (num === undefined) return "N/A";
      return new Intl.NumberFormat().format(Number(num));
   };

   const formatCurrency = (num: number | string | undefined) => {
      if (num === undefined) return "N/A";
      return `$${new Intl.NumberFormat("en-US", {
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }).format(Number(num))}`;
   };

   const cards = [
      { title: "Total Quotes", value: formatNumber(data?.totalQuotes) },
      { title: "Quoted Customers", value: formatNumber(data?.quotedCustomer) },
      { title: "Average quotes per customer", value: data?.avgQuotePerCustomer },
      { title: "Customers converted", value: formatNumber(data?.customersConverted) },
      { title: "Total Revenue", value: formatCurrency(data?.totalRevenu) },
      { title: "Quoted Products", value: formatNumber(data?.quotedProducts) },
      { title: "Quoted Value", value: formatCurrency(data?.quotedValue) },
      { title: "Quotes converted", value: formatNumber(data?.quotesConverted) },
      { title: "Avg Conversion Rate", value: parseFloat(data?.avgConvertionRate || "") * 100 + "%" },
      { title: "Average Conversion Days", value: parseInt(data?.avgConvertionPeriod || "") },
      { title: "New quote notifications", value: notificationsData?.totquotereminderinitial },
      { title: "First reminders", value: notificationsData?.totquotereminderfirst },
      { title: "Second reminders", value: notificationsData?.totquoteremindersecond },
      { title: "Final reminders", value: notificationsData?.totquotereminderfinal },
   ];

   return (
      <div className="grid mt-5 gap-4">
         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
            {cards.slice(0, 10).map((card, index) => (
               <Card key={index} loading={loading} title={card.title} value={card.value} />
            ))}
         </div>
         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ">
            {cards.slice(10).map((card, index) => (
               <Card key={index + 10} loading={loading} title={card.title} value={card.value} />
            ))}
         </div>
      </div>
   );
};

export default QuoteCards;
