import { useEffect, useState } from "react";
import { locationService } from "../../../api/services/location/location.service";
import { LocationResponse } from "./Location.type";

export const useLocations = () => {
   const [locations, setAllLocations] = useState<LocationResponse | null>(null);
   const [loading, setLoading] = useState(false);

   const fetchStores = async (pageSize = 50, lastItemId?: string) => {
      try {
         const response = await locationService.getAllLocations(pageSize, lastItemId);
         setAllLocations((prevLocations) => {
            const newLocations = response.data.locations;
            return prevLocations
               ? {
                    ...response.data,
                    locations: prevLocations.locations.concat(newLocations),
                 }
               : response.data;
         });

         if (response.data.pageSize >= 50) {
            await fetchStores(50, response.data.lastItemId);
         }
      } catch {
         console.log("Error fetching locations");
      }
   };

   useEffect(() => {
      const loadLocations = async () => {
         setLoading(true);
         await fetchStores();
         setLoading(false);
      };

      loadLocations();
   }, []);

   return { locations, loading };
};
