import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Input, Space, Popconfirm, Tooltip, Collapse, Form, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useRegions } from "../hooks/useRegions";
import { RegionModal } from "./RegionModal";
import { IRegion, regionService } from "../../../../api/services/region/region.service";
import { ILocation } from "../../../../components/UserAdministration/libs/Location.type";
import { useStores } from "../hooks/useStores";
import { StoreModal } from "./LocationModal";

const { Search } = Input;
const { Panel } = Collapse;

export const RegionsTab: React.FC<{ shouldRefresh?: boolean }> = ({ shouldRefresh }) => {
   const { regions, loading, fetchRegions, createRegion, updateRegion, deleteRegion } = useRegions();
   const { deleteStore, updateStore } = useStores();

   const [isRegionModalOpen, setIsRegionModalOpen] = useState(false);
   const [selectedRegion, setSelectedRegion] = useState<IRegion | null>(null);
   const [regionSearchQuery, setRegionSearchQuery] = useState("");
   const [storesByRegion, setStoresByRegion] = useState<Record<string, ILocation[]>>({});
   const storesByRegionRef = useRef<Record<string, ILocation[]>>({});
   const [loadingStoresByRegion, setLoadingStoresByRegion] = useState<Record<string, boolean>>({});
   const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
   const [selectedStore, setSelectedStore] = useState<ILocation | null>(null);
   const [storeForm] = Form.useForm();

   const handleStoreModalClose = () => {
      setIsStoreModalOpen(false);
      setSelectedStore(null);
      storeForm.resetFields();
   };

   const handleStoreEdit = (store: ILocation) => {
      setSelectedStore(store);
      storeForm.setFieldsValue({
         name: store.name,
         email: store.email,
         phone: store.address?.phone,
         locationRef: store.locationRef,
         regionRef: store.regionRef,
      });
      setIsStoreModalOpen(true);
   };

   const handleStoreUpdate = async (values: any) => {
      if (selectedStore) {
         const updatedStore = {
            id: selectedStore.id,
            locationRef: selectedStore.locationRef,
            regionRef: selectedStore.regionRef,
            name: values.name,
            email: values.email,
            address: {
               ...selectedStore.address,
               phone: values.phone,
            },
         };

         try {
            await updateStore(selectedStore.id || "", updatedStore);
            handleStoreModalClose();
            if (selectedStore.regionRef) {
               await loadRegionStores(selectedStore.regionRef);
            }
         } catch (error) {
            console.error('Failed to update store:', error);
         }
      } else {
         handleStoreModalClose();
      }
   };

   const handleRegionModalClose = () => {
      setIsRegionModalOpen(false);
      setSelectedRegion(null);
   };

   const handleRegionEdit = (region: IRegion) => {
      setSelectedRegion(region);
      setIsRegionModalOpen(true);
   };

   const handleRegionSubmit = async (values: any) => {
      if (selectedRegion) {
         await updateRegion(selectedRegion.id, values);
      } else {
         await createRegion(values);
      }
      handleRegionModalClose();
   };
   
   useEffect(() => {
      fetchRegions();
   }, [fetchRegions, shouldRefresh]);

   useEffect(() => {
      if (regions.length > 0) {
         regions.forEach((region) => {
            loadRegionStores(region.regionRef);
         });
      }
   }, [regions]);


   const filteredRegions = regions.filter(
      (region) =>
         region.regionName?.toLowerCase().includes(regionSearchQuery.toLowerCase()) || region.regionRef?.toLowerCase().includes(regionSearchQuery.toLowerCase())
   );

   const loadRegionStores = async (regionRef: string) => {
      setLoadingStoresByRegion((prev) => ({ ...prev, [regionRef]: true }));
      try {
         const response = await regionService.getAllLocationForRegion(regionRef);
         const regionStores = response.data.locations || [];
         setStoresByRegion((prev) => {
            const updated = { ...prev, [regionRef]: regionStores };
            storesByRegionRef.current = updated;
            return updated;
         });
      } catch (error) {
         console.error("Failed to fetch stores:", error);
      } finally {
         setLoadingStoresByRegion((prev) => ({ ...prev, [regionRef]: false }));
      }
   };

   const locationColumns = [
      {
         title: "Store Number",
         dataIndex: "locationRef",
         key: "locationRef",
         render: (text: string) => text || "-",
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         render: (text: string) => text || "-",
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
         render: (text: string) => text || "-",
      },
      {
         title: "Phone",
         dataIndex: ["address", "phone"],
         key: "phone",
         render: (text: string) => text || "-",
      },
      {
         title: "Actions",
         key: "actions",
         render: (_: any, record: ILocation) => (
            <Space>
               <Tooltip title="Edit">
                  <Button icon={<EditOutlined />} onClick={() => handleStoreEdit(record)} />
               </Tooltip>
               <Popconfirm
                  title="Are you sure you want to delete this store?"
                  onConfirm={async () => {
                     await deleteStore(record.id || "", record.locationRef || "");
                     handleStoreModalClose();
                     await loadRegionStores(record.regionRef || "");
                  }}
                  okText="Yes"
                  cancelText="No"
               >
                  <Tooltip title="Delete">
                     <Button icon={<DeleteOutlined />} danger />
                  </Tooltip>
               </Popconfirm>
            </Space>
         ),
      },
   ];

   const extraPanel = (region: IRegion) => (
      <Space onClick={(e) => e.stopPropagation()}>
         <Tooltip title="Edit">
            <Button icon={<EditOutlined />} onClick={() => handleRegionEdit(region)} />
         </Tooltip>
         <Popconfirm title="Are you sure you want to delete this region?" onConfirm={() => deleteRegion(region.id)} okText="Yes" cancelText="No">
            <Tooltip title="Delete">
               <Button icon={<DeleteOutlined />} danger />
            </Tooltip>
         </Popconfirm>
      </Space>
   );

   return (
      <>
         <div className="flex justify-between items-center mb-4">
            <Search placeholder="Search regions..." className="max-w-md" allowClear onChange={(e) => setRegionSearchQuery(e.target.value)} />
            <Button type="primary" className="!bg-[#59c3b8] !border-[#59c3b8]" onClick={() => setIsRegionModalOpen(true)}>
               Add Region
            </Button>
         </div>

         <Spin spinning={loading}>
            <Collapse
               onChange={(key) => {
                  if (key) {
                     if (typeof key === "string") {
                        loadRegionStores(key);
                     } else if (Array.isArray(key)) {
                        key.forEach((k) => loadRegionStores(k));
                     }
                  }
               }}
            >
               {filteredRegions.map((region) => (
                  <Panel key={region.regionRef} header={`${region.regionName} (${region.regionRef})`} extra={extraPanel(region)}>
                     <Table
                        columns={locationColumns}
                        dataSource={storesByRegion[region.regionRef]}
                        loading={loadingStoresByRegion[region.regionRef]}
                        rowKey="id"
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                     />
                  </Panel>
               ))}
            </Collapse>
         </Spin>

         <RegionModal visible={isRegionModalOpen} onCancel={handleRegionModalClose} onSubmit={handleRegionSubmit} editingRegion={selectedRegion} />
         <StoreModal
            visible={isStoreModalOpen}
            onCancel={handleStoreModalClose}
            onSubmit={handleStoreUpdate}
            editingStore={selectedStore}
            form={storeForm}
            regions={true}
         />
      </>
   );
};
