import { useEffect, useState, useCallback } from "react";
import { DatePicker, Form, Select, Space } from "antd";
import { DateIcon } from "../../../../assets/icon/DateIcon";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { DateBeforeDays } from "../../Dashboard/components/Utils";
import { ClickHouseDashboardApi } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import Card from "../../Dashboard/SimpleBoxMain/Card";

dayjs.extend(weekday);
dayjs.extend(localeData);

interface DashboardData {
   dashboardData: {
      totInteraction: number;
      totWishInteraction: number;
      totCustInteraction: number;
      totMessageInteraction: number;
      totConversion: number;
      totConversionValue: string;
      totSales: number;
      influencedSales: number;
   };
}

interface DateRange {
   from: string;
   to: string;
}

const KpiStats = () => {
   const [data, setData] = useState<DashboardData | null>(null);
   const [loading, setLoading] = useState(false);
   const [filterValue, setFilterValue] = useState<string>("30");
   const [dateValue, setDateValue] = useState<any>(null);
   const [dateRange, setDateRange] = useState<DateRange>({
      from: DateBeforeDays(30),
      to: DateBeforeDays(0),
   });

   const showCustomDateRange = filterValue === "custom";

   const handleFilterChange = (value: string) => {
      setFilterValue(value);
      if (value !== "custom") {
         setDateRange({
            from: DateBeforeDays(Number(value)),
            to: DateBeforeDays(0),
         });
      }
   };

   const handleDateRangeChange = (dates: any, dateStrings: string[]) => {
      setDateValue(dates);
      if (dates) {
         setDateRange({ from: dateStrings[0], to: dateStrings[1] });
      }
   };

   const fetchData = useCallback(async () => {
      setLoading(true);
      try {
         const response = await ClickHouseDashboardApi.getKpiStats({
            from: dateRange.from,
            to: dateRange.to,
         }, "KPI");
         setData(JSON.parse(response));
      } catch (error) {
         console.error("Error fetching data:", error);
      } finally {
         setLoading(false);
      }
   }, [dateRange]);

   useEffect(() => {
      fetchData();
   }, [fetchData, dateRange]);

   const formatNumber = (num: number | undefined) => {
      if (num === undefined) return null;
      return new Intl.NumberFormat().format(num);
   };
   const formatCurrency = (value: string | number | undefined) => {
      const num = parseFloat(String(value));
      if (isNaN(num)) return null;
      return `$${new Intl.NumberFormat().format(num)}`;
   };

   return (
      <div className=" px-2 py-5 w-full">
         <div className="flex justify-between items-center">
            <h3 className="font-semibold text-xl mb-0">Company Analysis</h3>
            <Space wrap>
               {showCustomDateRange && <DatePicker.RangePicker value={dateValue} onChange={handleDateRangeChange} />}
               <Select
                  placeholder="Filter"
                  className="w-full"
                  value={filterValue}
                  onChange={handleFilterChange}
                  options={[
                     { value: "7", label: "Past 7 days" },
                     { value: "30", label: "Past 30 days" },
                     { value: "60", label: "Past 60 days" },
                     { value: "90", label: "Past 90 days" },
                     { value: "custom", label: "Custom" },
                  ]}
               />
            </Space>
         </div>
         <div className="grid mt-5">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 ">
               <Card
                  loading={loading}
                  title="Total interactions"
                  tooltip="The total number of wishlist and wishlist item interactions (adds, deletes and changes), changes to customer contacts in-store and messages sent to customers in the selected period"
                  value={formatNumber(data?.dashboardData?.totInteraction)}
               />
               <Card loading={loading} title="Total wishlist interactions" tooltip="The total number of wishlist and wishlist item interactions (adds, deletes and changes) in the selected period" value={formatNumber(data?.dashboardData?.totWishInteraction)} />
               <Card
                  loading={loading}
                  title="Total customer contact updates"
                  tooltip="The total number of contact updates in store during the given period"
                  value={formatNumber(data?.dashboardData?.totCustInteraction)}
               />
               <Card loading={loading} title="Total messages" tooltip="The total number of messages sent to, or received from customers" value={formatNumber(data?.dashboardData?.totMessageInteraction)} />
               <Card loading={loading} title="Total number of conversions" tooltip="The number of orders in the specified period that included items that were either on that customer’s wishlist, or that were previously sent to that customer in a message" value={formatNumber(data?.dashboardData?.totConversion)} />
               <Card
                  loading={loading}
                  title="Total value of conversions"
                  tooltip="The total value of orders in the specified period that included items that were either on that customer’s wishlist, or that were previously sent to that customer in a message"
                  value={formatCurrency(parseFloat(data?.dashboardData?.totConversionValue || "").toFixed(0))}
               />
               <Card loading={loading} title="Total member sales" tooltip="The total member sales in the specified period" value={formatCurrency(data?.dashboardData?.totSales)} />
               <Card
                  loading={loading}
                  title="Influenced member sales"
                  tooltip="The percentage of member sales that were influenced by wishlist or message actions in the specified period"
                  value={data?.dashboardData?.influencedSales ? (data?.dashboardData?.influencedSales * 100).toFixed(1) + "%" : "N/A"}
               />
            </div>
         </div>
      </div>
   );
};

export default KpiStats;
