import React, { useEffect } from "react";
import { Tabs } from "antd";
import { LocationsTab } from "./components/LocationsTab";
import { RegionsTab } from "./components/RegionsTab";
import { useHistory } from "react-router-dom";
import { Modules } from "../../../utility/helpers/checkModuleAccess";
import { checkModuleAccess } from "../../../utility/helpers/checkModuleAccess";

const { TabPane } = Tabs;

const ManageStores: React.FC = () => {
   const history = useHistory();

   useEffect(() => {
      if (!checkModuleAccess(Modules.STORE_POS)) {
         history.push("/404");
      }
   }, [history]);

   return (
      <div className="p-6">
         <div className="mb-6">
            <h1 className="text-2xl font-semibold mb-4">Store & Region Management</h1>
            <Tabs
               defaultActiveKey="stores"
               className="[&_.ant-tabs-ink-bar]:!bg-[#59c3b8] [&_.ant-tabs-tab-active_.ant-tabs-tab-btn]:!text-[#59c3b8] [&_.ant-tabs-tab-btn:hover]:!text-[#59c3b8]"
            >
               <TabPane tab="Stores" key="stores">
                  <LocationsTab />
               </TabPane>
               <TabPane tab="Regions" key="regions">
                  <RegionsTab />
               </TabPane>
            </Tabs>
         </div>
      </div>
   );
};

export default ManageStores;
